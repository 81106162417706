//////////////////////////////
// Scripts
//////////////////////////////

$(function() { // On DOM ready

    // Do magic here
    $('textarea#myTweet').on('input focus', function(){
        var text = $('textarea#myTweet').val();
        var maxchars = $('span#maxRemainder').text();
        var charsleft = maxchars - twttr.txt.getTweetLength(text);
        $('span#myRemainder').text(charsleft);
    });

    // Editor
    // Source: https://www.froala.com/wysiwyg-editor/docs
    $('#edit').froalaEditor({
        key: 'CofhaoioldC10vhkcqC-7veqA-16zE-11C-9G2tw==',
        height: '300',
        inlineMode: false,
        language: 'nl',
        toolbarButtons: [
            'fullscreen',
            'bold',
            'italic',
            'formatUL',
            'insertLink',
            'undo',
            'redo',
            'clearFormatting',
            'selectAll'
        ],
        placeholderText: 'Type hier het bericht'

    })
});
